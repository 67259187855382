<template>
    <div>
        <!--面包屑-->
        <div class="page-breadcrumb">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>{{ page_name }}</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <!--内容-->
        <div class="page-content-x">
            <!--标题-->
            <el-row>
                <el-col :span="12"><h3>{{ time }}{{ page_name }}</h3></el-col>
            </el-row>
            <!--搜索-->
            <div class="page-content-search">
                <el-form ref="form" :model="search" size="medium" :label-width="this.env.search_label_width">
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="搜索时间">
                                <div class="block">
                                    <el-date-picker
                                            v-model="time"
                                            type="month"
                                            placeholder="选择月"
                                            value-format="yyyy-MM"
                                    >
                                    </el-date-picker>
                                </div>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row class="page-content-search-button">
                        <el-col :span="24">
                            <el-form-item>
                                <el-button icon="el-icon-search" size="medium" type="primary" @click="is_search">搜索
                                </el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </div>

            <!--收入-->
            <h3>总数据</h3>
            <el-row :gutter="16">
                <el-col :span="6" v-if="is_auth('accountbook.monthly.pay_total_money')">
                    <el-card shadow="hover">
                        <div class="d-flex">
                            <i class="icon fa fa-jpy default"></i>
                            <div>
                                <div>￥{{ monthly.pay_total_money }}</div>
                                <div>总支付</div>
                            </div>
                        </div>
                    </el-card>
                </el-col>
                <el-col :span="6" v-if="is_auth('accountbook.monthly.balance_total_money')">
                    <el-card shadow="hover">
                        <div class="d-flex">
                            <i class="icon fa fa-credit-card-alt default"></i>
                            <div>
                                <div>￥{{ monthly.balance_total_money }}</div>
                                <div>余额支付</div>
                            </div>
                        </div>
                    </el-card>
                </el-col>
                <el-col :span="6" v-if="is_auth('accountbook.monthly.wx_total_money')">
                    <el-card shadow="hover">
                        <div class="d-flex">
                            <i class="icon fa fa-weixin default"></i>
                            <div>
                                <div>￥{{ monthly.wx_total_money }}</div>
                                <div>微信支付</div>
                            </div>
                        </div>
                    </el-card>
                </el-col>
                <el-col :span="6" v-if="is_auth('accountbook.monthly.coupon_total_money')">
                    <el-card shadow="hover">
                        <div class="d-flex">
                            <i class="icon fa fa-ticket default"></i>
                            <div>
                                <div>￥{{ monthly.coupon_total_money }}</div>
                                <div>优惠金额</div>
                            </div>
                        </div>
                    </el-card>
                </el-col>
            </el-row>
            <div style="height: 20px"></div>

            <!--团课-->
            <h3>团课</h3>
            <el-row :gutter="16">
                <el-col :span="6" v-if="is_auth('accountbook.monthly.group_total_money')">
                    <el-card shadow="hover">
                        <div class="d-flex">
                            <i class="icon fa fa-jpy blue"></i>
                            <div>
                                <div>￥{{ monthly.group_total_money }}</div>
                                <div>团课上课收入</div>
                            </div>
                        </div>
                    </el-card>
                </el-col>
                <el-col :span="6" v-if="is_auth('accountbook.monthly.group_sum')">
                    <el-card shadow="hover">
                        <div class="d-flex">
                            <i class="icon el-icon-s-order blue"></i>
                            <div>
                                <div>{{ monthly.group_sum }}节</div>
                                <div>团课完成数量</div>
                            </div>
                        </div>
                    </el-card>
                </el-col>
                <el-col :span="6" v-if="is_auth('accountbook.monthly.group_people_sum')">
                    <el-card shadow="hover">
                        <div class="d-flex">
                            <i class="icon fa fa-users blue"></i>
                            <div>
                                <div>{{ monthly.group_people_sum }}人次</div>
                                <div>团课预约人次</div>
                            </div>
                        </div>
                    </el-card>
                </el-col>
                <!--                <el-col :span="6">
                                    <el-card shadow="hover">
                                        <div class="d-flex">
                                            <i class="icon el-icon-success blue"></i>
                                            <div>
                                                <div>44444</div>
                                                <div>当月-团课预约人数</div>
                                            </div>
                                        </div>
                                    </el-card>
                                </el-col>-->
            </el-row>
            <div style="height: 20px"></div>

            <!--训练营-->
            <h3>训练营</h3>
            <el-row :gutter="16">
                <el-col :span="6" v-if="is_auth('accountbook.monthly.camp_total_money')">
                    <el-card shadow="hover">
                        <div class="d-flex">
                            <i class="icon fa fa-jpy yellow"></i>
                            <div>
                                <div>￥{{ monthly.camp_total_money }}</div>
                                <div>训练营上课收入</div>
                            </div>
                        </div>
                    </el-card>
                </el-col>
                <el-col :span="6" v-if="is_auth('accountbook.monthly.camp_sum')">
                    <el-card shadow="hover">
                        <div class="d-flex">
                            <i class="icon el-icon-s-order yellow"></i>
                            <div>
                                <div>{{ monthly.camp_sum }}节</div>
                                <div>训练营上课数量</div>
                            </div>
                        </div>
                    </el-card>
                </el-col>
            </el-row>
            <div style="height: 20px"></div>

            <!--私教-->
            <h3>私教</h3>
            <el-row :gutter="16">
                <el-col :span="6" v-if="is_auth('accountbook.monthly.pt_total_money')">
                    <el-card shadow="hover">
                        <div class="d-flex">
                            <i class="icon fa fa-jpy red"></i>
                            <div>
                                <div>￥{{ monthly.pt_total_money }}</div>
                                <div>私教上课收入</div>
                            </div>
                        </div>
                    </el-card>
                </el-col>
                <el-col :span="6" v-if="is_auth('accountbook.monthly.pt_sum')">
                    <el-card shadow="hover">
                        <div class="d-flex">
                            <i class="icon el-icon-s-order red"></i>
                            <div>
                                <div>{{ monthly.pt_sum }}节</div>
                                <div>私教上课数量</div>
                            </div>
                        </div>
                    </el-card>
                </el-col>
            </el-row>
            <div style="height: 20px"></div>

        </div>
    </div>
</template>
<script>


export default {

    data() {
        return {
            page_name: '月数据',
            search: {},         // 搜索内容
            time: '',
            monthly: {
                // 总数据
                pay_total_money: 0,             // 总支付
                balance_total_money: 0,         // 余额收入
                wx_total_money: 0,              // 微信收入
                coupon_total_money: 0,          // 优惠金额

                // 团课相关
                group_sum: 0,                   // 团课上课量
                group_people_sum: 0,            // 团课预约人次
                group_total_money: 0,           // 团课上课收入

                // 训练营相关
                camp_sum: 0,                    // 训练营上课量
                camp_total_money: 0,            // 训练营上课收入

                // 私教相关
                pt_sum: 0,                      // 私教上课量
                pt_total_money: 0,              // 私教上课收入
            },
        }
    },
    // 创建
    created() {
        this.getdatatime()
        this.is_search()
    },
    // 安装
    mounted() {

    },
    methods: {
        getdatatime() {//默认显示今天
            let date = new Date();
            let y = date.getFullYear();
            let M = date.getMonth() + 1;
            let theDay = `${y.toString()}-${M.toString()}`;
            this.time = theDay;
        },
        // 权限判断
        is_auth(auth) {
            return this.Tool.is_auth(auth)
        },

        //搜索
        is_search() {
            for (let key in this.monthly) {
                this.get_monthly_data(key)
            }
        },
        // 获取数据
        get_monthly_data(key) {
            let postdata = {
                api_name: 'accountbook.monthly.' + key,
                token: this.Tool.get_l_cache('token'),
                time: this.time,
            }
            this.Tool.post_data('oss', postdata, (json) => {
                if (json.code === 0) {
                    this.monthly[key] = json.data[key]
                }
            })
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.d-flex {
    display: flex;
    align-items: center;
    justify-items: center;
}

.default {
    color: #409EFF;
}

.red {
    color: #fc142f;
}

.yellow {
    color: #F4BA28;
}

.blue {
    color: #637FFB;
}

.icon {
    font-size: 60px;
    margin-right: 20px;
}
</style>
